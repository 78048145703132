import React, { useState, useEffect } from "react";
import _ from "underscore";
import ErrorMdl from "../components/errorMdl";
import PageContentEvidence from "../components/PageContentEvidence";
import Helmet from "react-helmet";
import {
  setQueryStringValue,
  getQueryStringValue,
} from "../components/search/queryString";

const Evidence = (props) => {
  const [errorshow, setErrorShow] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    !_.isUndefined(getQueryStringValue("evidence_vol"))
      ? getQueryStringValue("evidence_vol")
      : ""
  );
  const [data, setData] = useState([]);
  document.title = "Evidence: Trip Medical Database";
  useEffect(() => {
    document.body.classList.remove("homepage");
    // var page = !_.isUndefined(props.match.params)
    //   ? props.match.params.criteria
    //   : "";
    // let metaTag = document.querySelector(`meta[name="description"]`);
    // if (metaTag) {
    //   metaTag.remove();
    // }
  }, []);
  return (
    <main role="main">
      <Helmet>
        <link
          rel="preload"
          as="style"
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
        />

        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
          media="print"
          onload="this.media='all'"
        />
        <link
          rel="stylesheet"
          id="main-stylesheet-css"
          href="https://evidence.tripdatabase.com/wp-content/themes/tripevidence/dist/css/global.css"
          media="all"
        />
        <link
          rel="https://api.w.org/"
          href="https://evidence.tripdatabase.com/wp-json/"
        />
        <link
          rel="alternate"
          title="JSON"
          type="application/json"
          href="https://evidence.tripdatabase.com/wp-json/wp/v2/pages/12"
        />
        <link
          rel="alternate"
          title="oEmbed (JSON)"
          type="application/json+oembed"
          href="https://evidence.tripdatabase.com/wp-json/oembed/1.0/embed?url=https://evidence.tripdatabase.com%2F"
        />
        <link
          rel="stylesheet"
          id="wp-block-library-css"
          href="https://evidence.tripdatabase.com/wp-includes/css/dist/block-library/style.min.css?ver=6.6.2"
          media="all"
        />
        <link
          rel="alternate"
          title="oEmbed (XML)"
          type="text/xml+oembed"
          href="https://evidence.tripdatabase.com/wp-json/oembed/1.0/embed?url=https://evidence.tripdatabase.com%2F&#038;format=xml"
        />
      </Helmet>
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />

      <div>
        <PageContentEvidence
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          data={data}
          error={hasError}
          errorsShow={errorshow}
          setData={setData}
          setErrors={setErrors}
          setErrorShow={setErrorShow}
        />
      </div>
    </main>
  );
};
export default Evidence;
